import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
// components
import eyeOutline from '@iconify/icons-eva/eye-outline';
import plusFill from '@iconify/icons-eva/plus-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import { Icon } from '@iconify/react';
import { Grid, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Slide from '@material-ui/core/Slide';
import { MobileDatePicker, MobileDateTimePicker } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import axios from 'axios';
import viLocale from 'date-fns/locale/vi';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import TimecardListHead from 'src/components/_dashboard/timecards/TimecardListHead';
import TimecardMoreMenu from 'src/components/_dashboard/timecards/TimecardMoreMenu';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import * as Config from '../constants/config';
import CallAPI from './../services/CallAPI';

const TABLE_HEAD = [
  { id: 'STT', label: 'STT', alignRight: false },
  { id: 'date', label: 'Ngày', alignRight: false },
  { id: 'username', label: 'Mã NV', alignRight: false },
  { id: 'begin', label: 'Vào ca', alignRight: false },
  { id: 'endTime', label: 'Kết Ca', alignRight: false },
  { id: 'statusWork', label: 'Trạng thái', alignRight: false },
  { id: 'numberOfBreaks', label: 'Số lần nghỉ trong ngày', alignRight: false },
  { id: 'sumWorkingTime', label: 'Tổng thời gian làm việc', alignRight: false }
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// ----------------------------------------------------------------------

export default function Timecard() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [breakTimeTemp, setBreakTimeTemp] = React.useState(null);
  const [doneBreakTimeTemp, setDoneBreakTimeTemp] = React.useState(null);

  const [breakTime2Temp, setBreakTime2Temp] = React.useState(null);
  const [doneBreakTime2Temp, setDoneBreakTime2Temp] = React.useState(null);

  const [breakTime3Temp, setBreakTime3Temp] = React.useState(null);
  const [doneBreakTime3Temp, setDoneBreakTime3Temp] = React.useState(null);

  const [breakTime4Temp, setBreakTime4Temp] = React.useState(null);
  const [doneBreakTime4Temp, setDoneBreakTime4Temp] = React.useState(null);

  const [openNumberOfBreaks, setOpenNumberOfBreaks] = React.useState(false);

  const handleClickOpenNumberOfBreaks = (
    breakTime,
    doneBreakTime,
    breakTime2,
    doneBreakTime2,
    breakTime3,
    doneBreakTime3,
    breakTime4,
    doneBreakTime4
  ) => {
    setBreakTimeTemp(breakTime);
    setDoneBreakTimeTemp(doneBreakTime);

    setBreakTime2Temp(breakTime2);
    setDoneBreakTime2Temp(doneBreakTime2);

    setBreakTime3Temp(breakTime3);
    setDoneBreakTime3Temp(doneBreakTime3);

    setBreakTime4Temp(breakTime4);
    setDoneBreakTime4Temp(doneBreakTime4);

    setOpenNumberOfBreaks(true);
  };

  const handleCloseNumberOfBreaks = () => {
    setOpenNumberOfBreaks(false);
  };

  const [chooseFromDate, setChooseFromDate] = React.useState(moment(new Date()).startOf('month'));

  const [chooseToDate, setChooseToDate] = React.useState(new Date());

  const [chooseUsernameFilter, setChooseUsernameFilter] = React.useState('');

  const [chooseStatusWorkFilter, setChooseStatusWorkFilter] = React.useState(null);

  const [openSignUp, setOpenSignUp] = React.useState(false);

  const handleClickOpenSignUp = () => {
    setOpenSignUp(true);
    getEmployees();
  };

  const handleCloseSignUp = () => {
    setOpenSignUp(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleCloseSignUp();

    CallAPI(
      'v1/timecards',
      'POST',
      {
        statusWork: chooseStatusWork,
        date: chooseDate,
        beginTime: beginTime
          ? moment(new Date(chooseDate).setHours(beginTime.slice(0, 2), beginTime.slice(3, 5)))
          : undefined,
        breakTime: breakTime
          ? moment(new Date(chooseDate).setHours(breakTime.slice(0, 2), breakTime.slice(3, 5)))
          : undefined,
        doneBreakTime: doneBreakTime
          ? moment(
              new Date(chooseDate).setHours(doneBreakTime.slice(0, 2), doneBreakTime.slice(3, 5))
            )
          : undefined,
        breakTime2: breakTime2
          ? moment(new Date(chooseDate).setHours(breakTime2.slice(0, 2), breakTime2.slice(3, 5)))
          : undefined,
        doneBreakTime2: doneBreakTime2
          ? moment(
              new Date(chooseDate).setHours(doneBreakTime2.slice(0, 2), doneBreakTime2.slice(3, 5))
            )
          : undefined,
        breakTime3: breakTime3
          ? moment(new Date(chooseDate).setHours(breakTime3.slice(0, 2), breakTime3.slice(3, 5)))
          : undefined,
        doneBreakTime3: doneBreakTime3
          ? moment(
              new Date(chooseDate).setHours(doneBreakTime3.slice(0, 2), doneBreakTime3.slice(3, 5))
            )
          : undefined,
        breakTime4: breakTime4
          ? moment(new Date(chooseDate).setHours(breakTime4.slice(0, 2), breakTime4.slice(3, 5)))
          : undefined,
        doneBreakTime4: doneBreakTime4
          ? moment(
              new Date(chooseDate).setHours(doneBreakTime4.slice(0, 2), doneBreakTime4.slice(3, 5))
            )
          : undefined,
        numberOfBreaks: chooseNumberOfBreaks,
        endTime: endTime,
        userId: chooseEmployee
      },
      sessionStorage.getItem('jwt')
    )
      .then((res) => {
        if (res.status === 201) {
          toast.success('🦄 Tạo thẻ thời gian thành công!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          handleResetInput();
          getTimecards();
        }
      })
      .catch((err) => {
        console.log('inside catch block.');
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
          console.log(err.response.data.m);
        } else if (err.request) {
          console.log(err.request);
        } else {
          toast.error('Tạo thẻ thời gian thất bại!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
        console.log(JSON.stringify(err));
      });
  };

  const [timecards, setTimecards] = useState([]);
  const [totalTimecards, setTotalTimecards] = useState(0);

  const getTimecards = useCallback(async () => {
    const fromISODate = moment(chooseFromDate, 'DD/MM/YYYY').toISOString();
    const toISODate = moment(chooseToDate, 'DD/MM/YYYY').toISOString();
    try {
      axios
        .get(
          `${Config.API_URL}v1/timecards?limit=${rowsPerPage}&page=${
            page + 1
          }&username=${chooseUsernameFilter}&from=${fromISODate}&to=${toISODate}` +
            `${chooseStatusWorkFilter ? `&statusWork=${chooseStatusWorkFilter}` : ''}`,
          {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('jwt') }
          }
        )
        .then((res) => {
          setTimecards(res.data.data);
          setTotalTimecards(res.data.total);
        });
    } catch (error) {
      console.log(error);
    }
  }, [
    rowsPerPage,
    page,
    chooseStatusWorkFilter,
    chooseFromDate,
    chooseToDate,
    chooseUsernameFilter
  ]);

  useEffect(() => {
    getTimecards();
  }, [
    rowsPerPage,
    page,
    chooseStatusWorkFilter,
    chooseFromDate,
    chooseToDate,
    chooseUsernameFilter,
    getTimecards
  ]);

  const [employees, setEmployees] = useState([]);

  function getEmployees() {
    axios
      .get(`${Config.API_URL}v1/users?limit=50&page=1&role=employee`, {
        headers: { Authorization: 'Bearer ' + sessionStorage.getItem('jwt') }
      })
      .then((res) => {
        setEmployees(res.data.users);
      });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = timecards.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalTimecards) : 0;

  const [chooseDate, setChooseDate] = React.useState(new Date());

  const [chooseEmployee, setChooseEmployee] = React.useState('');
  const handleChooseEmployee = (event) => {
    setChooseEmployee(event.target.value);
  };

  const [chooseNumberOfBreaks, setChooseNumberOfBreaks] = React.useState(0);
  const handleChooseNumberOfBreaks = (event) => {
    setChooseNumberOfBreaks(event.target.value);
  };

  const [chooseStatusWork, setChooseStatusWork] = React.useState('vao_ca');

  const handleStatusWork = (event) => {
    setChooseStatusWork(event.target.value);
  };

  const [beginTime, setBeginTime] = React.useState(null);
  const handleBeginTime = (event) => {
    setBeginTime(event.target.value);
  };

  const [breakTime, setBreakTime] = React.useState(null);
  const handleBreakTime = (event) => {
    setBreakTime(event.target.value);
  };

  const [doneBreakTime, setDoneBreakTime] = React.useState(null);
  const handleDoneBreakTime = (event) => {
    setDoneBreakTime(event.target.value);
  };

  const [breakTime2, setBreakTime2] = React.useState(null);
  const handleBreakTime2 = (event) => {
    setBreakTime2(event.target.value);
  };

  const [doneBreakTime2, setDoneBreakTime2] = React.useState(null);
  const handleDoneBreakTime2 = (event) => {
    setDoneBreakTime2(event.target.value);
  };

  const [breakTime3, setBreakTime3] = React.useState(null);
  const handleBreakTime3 = (event) => {
    setBreakTime3(event.target.value);
  };

  const [doneBreakTime3, setDoneBreakTime3] = React.useState(null);
  const handleDoneBreakTime3 = (event) => {
    setDoneBreakTime3(event.target.value);
  };

  const [breakTime4, setBreakTime4] = React.useState(null);
  const handleBreakTime4 = (event) => {
    setBreakTime4(event.target.value);
  };

  const [doneBreakTime4, setDoneBreakTime4] = React.useState(null);
  const handleDoneBreakTime4 = (event) => {
    setDoneBreakTime4(event.target.value);
  };

  const handleRefresh = () => {
    setChooseFromDate(moment(new Date()).startOf('month'));
    setChooseToDate(new Date());
    setChooseUsernameFilter('');
    setChooseStatusWorkFilter('');
  };

  const handleResetInput = () => {
    setChooseStatusWork('vao_ca');
    setChooseDate(new Date());
    setBeginTime(null);
    setBreakTime(null);
    setDoneBreakTime(null);
    setBreakTime2(null);
    setDoneBreakTime2(null);
    setBreakTime3(null);
    setDoneBreakTime3(null);
    setBreakTime4(null);
    setDoneBreakTime4(null);
    setEndTime(null);
    setChooseNumberOfBreaks(0);
  };

  const [endTime, setEndTime] = React.useState(null);

  const handleChangeChooseUsername = (event) => {
    setChooseUsernameFilter(event.target.value);
  };
  const handleChangeChoooseStatusWorkFilter = (event) => {
    setChooseStatusWorkFilter(event.target.value);
  };

  return (
    <Page title="Thẻ Thời Gian |  Kim Long Tài">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Danh Sách Thẻ Thời Gian
          </Typography>
          <Button
            onClick={handleClickOpenSignUp}
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            Tạo thẻ thời gian
          </Button>
        </Stack>
        <Dialog
          fullWidth
          open={openSignUp}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseSignUp}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{'Tạo thông tin thẻ thời gian'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Hành động này sẽ khởi tạo một thẻ thời gian cho nhân viên
            </DialogContentText>
            <Container maxWidth="xl">
              <form>
                <br />
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={viLocale}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      <FormControl required fullWidth>
                        <InputLabel id="demo-simple-select-required-label">Nhân viên</InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-required-label"
                          id="demo-simple-select-required"
                          value={chooseEmployee}
                          label="Nhân viên"
                          onChange={handleChooseEmployee}
                        >
                          {employees.map((e) => {
                            if (e.nameStore !== 'VIP') {
                              return (
                                <MenuItem key={e.id} value={e.id}>
                                  {e.username + ' ' + e.fullName}
                                </MenuItem>
                              );
                            }
                            return null;
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                      <MobileDatePicker
                        fullWidth
                        label="Ngày"
                        value={chooseDate}
                        onChange={(newValue) => {
                          setChooseDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth required name="date" {...params} />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      required
                      fullWidth
                      id="datetime-local"
                      label="Vào ca"
                      type="time"
                      value={beginTime || ''}
                      onChange={handleBeginTime}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      id="datetime-local"
                      label="Nghỉ giữa giờ"
                      type="time"
                      value={breakTime || ''}
                      onChange={handleBreakTime}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      id="datetime-local"
                      label="Nghỉ xong"
                      type="time"
                      value={doneBreakTime || ''}
                      onChange={handleDoneBreakTime}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      id="datetime-local"
                      label="Nghỉ giữa giờ lần 2"
                      type="time"
                      value={breakTime2 || ''}
                      onChange={handleBreakTime2}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      id="datetime-local"
                      label="Nghỉ xong lần 2"
                      type="time"
                      value={doneBreakTime2 || ''}
                      onChange={handleDoneBreakTime2}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      id="datetime-local"
                      label="Nghỉ giữa giờ lần 3"
                      type="time"
                      value={breakTime3 || ''}
                      onChange={handleBreakTime3}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      id="datetime-local"
                      label="Nghỉ xong lần 3"
                      type="time"
                      value={doneBreakTime3 || ''}
                      onChange={handleDoneBreakTime3}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      id="datetime-local"
                      label="Nghỉ giữa giờ lần 4"
                      type="time"
                      value={breakTime4 || ''}
                      onChange={handleBreakTime4}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      id="datetime-local"
                      label="Nghỉ xong lần 4"
                      type="time"
                      value={doneBreakTime4 || ''}
                      onChange={handleDoneBreakTime4}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>

                    <MobileDateTimePicker
                      label="Ngày giờ kết ca"
                      value={endTime}
                      onChange={(newValue) => {
                        setEndTime(newValue);
                      }}
                      renderInput={(params) => <TextField fullWidth name="endTime" {...params} />}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <h4>Số lần nghỉ trong ngày</h4>
                    <Select
                      fullWidth
                      displayEmpty
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={chooseNumberOfBreaks}
                      onChange={handleChooseNumberOfBreaks}
                    >
                      <MenuItem value={0}>Chưa nghỉ</MenuItem>
                      <MenuItem value={1}>Nghỉ 1 lần</MenuItem>
                      <MenuItem value={2}>Nghỉ 2 lần</MenuItem>
                      <MenuItem value={3}>Nghỉ 3 lần</MenuItem>
                      <MenuItem value={4}>Nghỉ 4 lần</MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <h4>Trạng thái</h4>
                    <Select
                      fullWidth
                      displayEmpty
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={chooseStatusWork}
                      onChange={handleStatusWork}
                    >
                      <MenuItem value={'vao_ca'}>Vào Ca</MenuItem>
                      <MenuItem value={'nghi_giua_gio'}>Nghỉ Giữa Giờ</MenuItem>
                      <MenuItem value={'ly_do_nghi_giua_gio'}>Lý Do Nghỉ Giữa Giờ</MenuItem>
                      <MenuItem value={'nghi_xong'}>Nghỉ Xong</MenuItem>
                      <MenuItem value={'ket_ca'}>Kết Ca</MenuItem>
                    </Select>
                  </Grid>
                </LocalizationProvider>
              </form>
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSignUp} color="primary">
              Hủy bỏ
            </Button>
            <Button onClick={onSubmit} color="primary">
              Tạo thẻ thời gian
            </Button>
          </DialogActions>
        </Dialog>

        <Card>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
            mt={5}
            ml={2}
            mr={2}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={viLocale}>
              <MobileDatePicker
                label="Từ Ngày"
                value={chooseFromDate}
                onChange={(newValue) => {
                  setChooseFromDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField fullWidth style={{ margin: '5px' }} {...params} />
                )}
              />

              <MobileDatePicker
                label="Đến Ngày"
                value={chooseToDate}
                onChange={(newValue) => {
                  setChooseToDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField fullWidth style={{ margin: '5px' }} {...params} />
                )}
              />
            </LocalizationProvider>

            <TextField
              fullWidth
              style={{ margin: '5px' }}
              id="outlined-basic"
              label="Mã nhân viên"
              variant="outlined"
              value={chooseUsernameFilter}
              onChange={handleChangeChooseUsername}
            />

            <FormControl style={{ margin: '5px' }} fullWidth>
              <InputLabel id="demo-simple-select-label">Trạng Thái</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={chooseStatusWorkFilter}
                label="Trạng Thái"
                onChange={handleChangeChoooseStatusWorkFilter}
              >
                <MenuItem defaultValue key="all" value={null}>
                  Tất cả
                </MenuItem>
                <MenuItem key="vao_ca" value="vao_ca">
                  Vào Ca
                </MenuItem>
                <MenuItem key="nghi_giua_gio" value="nghi_giua_gio">
                  Nghỉ Giữa Giờ
                </MenuItem>
                <MenuItem key="ly_do_nghi_giua_gio" value="ly_do_nghi_giua_gio">
                  Lý Do Nghỉ Giữa Giờ
                </MenuItem>
                <MenuItem key="nghi_xong" value="nghi_xong">
                  Nghỉ Xong
                </MenuItem>
                <MenuItem key="dang_lam_viec" value="dang_lam_viec">
                  Đang Làm Việc
                </MenuItem>
                <MenuItem key="ket_ca" value="ket_ca">
                  Kết Ca
                </MenuItem>
              </Select>
            </FormControl>

            <Button
              onClick={handleRefresh}
              style={{ height: 55, margin: '5px' }}
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<Icon icon={refreshFill} />}
            ></Button>
          </Stack>
          <Card></Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TimecardListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={totalTimecards}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {timecards.map((row, stt = 0) => {
                    const {
                      id,
                      date,
                      beginTime,
                      breakTime,
                      doneBreakTime,
                      breakTime2,
                      doneBreakTime2,
                      breakTime3,
                      doneBreakTime3,
                      breakTime4,
                      doneBreakTime4,
                      statusWork,
                      numberOfBreaks,
                      sumWorkingTime,
                      endTime,
                      employee
                    } = row;
                    const isItemSelected = selected.indexOf(id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell align="left">{++stt}</TableCell>
                        <TableCell align="left">{moment(date).format('DD/MM/YYYY')}</TableCell>
                        <TableCell align="left">{employee.username}</TableCell>
                        <TableCell align="left">{moment(beginTime).format('HH:mm:ss')}</TableCell>

                        <TableCell align="left">
                          {statusWork === 'Kết ca'
                            ? moment(endTime).format('DD/MM/YYYY HH:mm:ss')
                            : 'đang làm việc'}
                        </TableCell>

                        <TableCell align="left">{statusWork}</TableCell>
                        <TableCell align="left">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              handleClickOpenNumberOfBreaks(
                                breakTime,
                                doneBreakTime,
                                breakTime2,
                                doneBreakTime2,
                                breakTime3,
                                doneBreakTime3,
                                breakTime4,
                                doneBreakTime4
                              )
                            }
                          >
                            <Icon style={{ fontSize: '25px' }} icon={eyeOutline} /> {numberOfBreaks}
                          </Button>
                        </TableCell>
                        <TableCell align="left">
                          {statusWork === 'Kết ca' ? sumWorkingTime : 'đang làm việc'}
                        </TableCell>

                        <TableCell align="right">
                          <TimecardMoreMenu
                            updateTimecards={() => getTimecards()}
                            idTimecard={id}
                            idStatusWork={statusWork.id}
                            idUser={null}
                            Nob={numberOfBreaks}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}

                  <Dialog open={openNumberOfBreaks} onClose={handleCloseNumberOfBreaks}>
                    <DialogTitle id="alert-dialog-slide-title">
                      Mốc Thời Gian Nghỉ Trong Ngày
                    </DialogTitle>
                    <DialogContent>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left">NGHỈ LẦN 1</TableCell>
                            <TableCell align="left">
                              {breakTimeTemp === null
                                ? ''
                                : moment(breakTimeTemp).format('HH:mm:ss').toString() === '00:00:00'
                                ? ''
                                : moment(breakTimeTemp).format('HH:mm:ss').toString() === '12:00:00'
                                ? ''
                                : moment(breakTimeTemp).format('HH:mm:ss').toString()}
                            </TableCell>
                            <TableCell align="left">
                              {doneBreakTimeTemp === null
                                ? ''
                                : moment(doneBreakTimeTemp).format('HH:mm:ss').toString() ===
                                  '00:00:00'
                                ? ''
                                : moment(doneBreakTimeTemp).format('HH:mm:ss').toString() ===
                                  '12:00:00'
                                ? ''
                                : moment(doneBreakTimeTemp).format('HH:mm:ss').toString()}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">NGHỈ LẦN 2</TableCell>
                            <TableCell align="left">
                              {breakTime2Temp === null
                                ? ''
                                : moment(breakTime2Temp).format('HH:mm:ss').toString() ===
                                  '00:00:00'
                                ? ''
                                : moment(breakTime2Temp).format('HH:mm:ss').toString() ===
                                  '12:00:00'
                                ? ''
                                : moment(breakTime2Temp).format('HH:mm:ss').toString()}
                            </TableCell>
                            <TableCell align="left">
                              {doneBreakTime2Temp === null
                                ? ''
                                : moment(doneBreakTime2Temp).format('HH:mm:ss').toString() ===
                                  '00:00:00'
                                ? ''
                                : moment(doneBreakTime2Temp).format('HH:mm:ss').toString() ===
                                  '12:00:00'
                                ? ''
                                : moment(doneBreakTime2Temp).format('HH:mm:ss').toString()}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">NGHỈ LẦN 3</TableCell>
                            <TableCell align="left">
                              {breakTime3Temp === null
                                ? ''
                                : moment(breakTime3Temp).format('HH:mm:ss').toString() ===
                                  '00:00:00'
                                ? ''
                                : moment(breakTime3Temp).format('HH:mm:ss').toString() ===
                                  '12:00:00'
                                ? ''
                                : moment(breakTime3Temp).format('HH:mm:ss').toString()}
                            </TableCell>
                            <TableCell align="left">
                              {doneBreakTime3Temp === null
                                ? ''
                                : moment(doneBreakTime3Temp).format('HH:mm:ss').toString() ===
                                  '00:00:00'
                                ? ''
                                : moment(doneBreakTime3Temp).format('HH:mm:ss').toString() ===
                                  '12:00:00'
                                ? ''
                                : moment(doneBreakTime3Temp).format('HH:mm:ss').toString()}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">NGHỈ LẦN 4</TableCell>
                            <TableCell align="left">
                              {breakTime4Temp === null
                                ? ''
                                : moment(breakTime4Temp).format('HH:mm:ss').toString() ===
                                  '00:00:00'
                                ? ''
                                : moment(breakTime4Temp).format('HH:mm:ss').toString() ===
                                  '12:00:00'
                                ? ''
                                : moment(breakTime4Temp).format('HH:mm:ss').toString()}
                            </TableCell>
                            <TableCell align="left">
                              {doneBreakTime4Temp === null
                                ? ''
                                : moment(doneBreakTime4Temp).format('HH:mm:ss').toString() ===
                                  '00:00:00'
                                ? ''
                                : moment(doneBreakTime4Temp).format('HH:mm:ss').toString() ===
                                  '12:00:00'
                                ? ''
                                : moment(doneBreakTime4Temp).format('HH:mm:ss').toString()}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseNumberOfBreaks} color="primary">
                        Tắt
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalTimecards}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Page>
  );
}
