import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Attendance from './pages/Attendance/Attendance';
import NewAttendance from './pages/Attendance/NewAttendance';
import DashboardApp from './pages/DashboardApp';
import Login from './pages/Login';
import Note from './pages/Note';
import Notification from './pages/Notification';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import RegularMessage from './pages/RegularMessage';
import Reports from './pages/Reports';
import ReportsByMonth from './pages/ReportsByMonth';
import Store from './pages/Store';
import Timecard from './pages/Timecard';
import User from './pages/User';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: localStorage.getItem('jwt') ? <DashboardLayout /> : <Navigate to="/login" replace />,
      children: [
        {
          path: '/',
          element: localStorage.getItem('jwt') ? (
            <Navigate to="/dashboard/app" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        },
        { path: 'app', element: <DashboardApp /> },
        { path: 'users', element: <User /> },
        { path: 'notes', element: <Note /> },
        { path: 'regular-messages', element: <RegularMessage /> },
        { path: 'stores', element: <Store /> },
        { path: 'attendances', element: <Attendance /> },
        { path: 'new-attendances', element: <NewAttendance /> },
        { path: 'timecards', element: <Timecard /> },
        { path: 'reports/employee', element: <Reports /> },
        { path: 'reports/month', element: <ReportsByMonth /> },
        { path: 'notification', element: <Notification /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
