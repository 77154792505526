import { Box, Typography, Button, Chip, Collapse, Container, DialogActions, DialogContent, DialogContentText, Slide, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { LoadingButton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { toast } from 'react-toastify';
import CallAPI from "src/services/CallAPI";
import { Link as RouterLink } from 'react-router-dom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Icon } from '@iconify/react';
import Dialog from '@material-ui/core/Dialog';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import IconButton from '@material-ui/core/IconButton';
const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset'
      }
    }
});
  

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function RowAttendance(props, ref) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
  
    const classes = useRowStyles();
  
    const [isSubmittingDelete, setIsSubmittingDelete] = useState(false);
    const [openCreateDeleteDateAttendance, setOpenCreateDeleteDateAttendance] = React.useState(false);
  
    const handleClickOpenCreateDelete = () => {
      setOpenCreateDeleteDateAttendance(true);
    };
  
    const handleCloseCreateDelete = () => {
      setOpenCreateDeleteDateAttendance(false);
    };
  
    const RenderRowAttendance = (props) => {
      const handleClickStatusChangeFalse = (id) => {
        CallAPI(
          `/attendances/${id}`,
          'PUT',
          {
            check: false
          },
          sessionStorage.getItem('jwt')
        )
          .then((res) => {
            if (res.status === 204) {
              toast.success('🦄 Cật nhật thành công', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
            }
   
          })
          .catch((err) => {
            console.log('inside catch block.');
            if (err.response) {
              console.log(err.response.data);
              console.log(err.response.status);
              console.log(err.response.headers);
            } else if (err.request) {
              console.log(err.request);
            } else {
              console.log('Error', err.message);
              toast.error('Lỗi! Cật nhật thất bại!', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
            }
            console.log(JSON.stringify(err));
          });
      };
  
      const handleClickStatusChangeTrue = (id) => {
        CallAPI(
          `/attendances/${id}`,
          'PUT',
          {
            check: true
          },
          sessionStorage.getItem('jwt')
        )
          .then((res) => {
            if (res.status === 200) {
              toast.success('🦄 Cật nhật thành công', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
            }
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          })
          .catch((err) => {
            console.log('inside catch block.');
            if (err.response) {
              console.log(err.response.data);
              console.log(err.response.status);
              console.log(err.response.headers);
            } else if (err.request) {
              console.log(err.request);
            } else {
              console.log('Error', err.message);
              toast.error('Lỗi! Cật nhật thất bại!', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined
              });
            }
            console.log(JSON.stringify(err));
          });
      };
  
      return (
        <TableRow key={props.id}>
          <TableCell component="th" scope="row">
            {props.username}
          </TableCell>
          <TableCell component="th" scope="row">
            {props.fullName}
          </TableCell>
  
          <TableCell component="th" scope="row">
            {props?.store?.name}
          </TableCell>
          <TableCell component="th" scope="row">
            {props.phoneNumber}
          </TableCell>
  
          <TableCell component="th" scope="row">
            {props.role === 'Quản lý' ? (
              <Chip label="Quản lý" />
            ) : props.role === 'Nhân viên' ? (
              <Chip color="secondary" variant="outlined" label="Nhân Viên" />
            ) : (
              <Chip color="warning" variant="outlined" label="Developer" />
            )}
          </TableCell>
          <TableCell>
            {props.check === true ? (
              <Chip
                color="secondary"
                label="Đã điểm danh"
                onClick={() => handleClickStatusChangeFalse(props.id)}
              />
            ) : (
              <Chip
                color="primary"
                label="Chưa điểm danh"
                onClick={() => handleClickStatusChangeTrue(props.id)}
              />
            )}
          </TableCell>
        </TableRow>
      );
    };
  
    const deleteDateAttendance = (id) => {
      handleCloseCreateDelete();
  
      setIsSubmittingDelete(true);
  
      CallAPI(`v1/attendances/${id}/delete`, 'PUT', null, sessionStorage.getItem('jwt'))
        .then((res) => {
          setIsSubmittingDelete(false);
          if (res.status === 200) {
            toast.success('🦄 Xóa điểm danh theo ngày thành công! ', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          console.log('inside catch block.');
          if (err.response) {
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
          } else if (err.request) {
            console.log(err.request);
          } else {
            console.log('Error', err.message);
            toast.error('Lỗi! Thất bại', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
          console.log(JSON.stringify(err));
        });
    };
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.date}
          </TableCell>
          <TableCell component="th" scope="row">
            <LoadingButton
              onClick={handleClickOpenCreateDelete}
              loading={isSubmittingDelete}
              variant="outlined"
              component={RouterLink}
              to="#"
              startIcon={<Icon icon={trash2Fill} />}
              color="primary"
            >
              Xóa danh sách điểm danh theo ngày
            </LoadingButton>
  
            <Dialog
              open={openCreateDeleteDateAttendance}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleCloseCreateDelete}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Bạn có chắc chắn muốn xóa ngày điểm danh này ?
                </DialogContentText>
  
                <Container maxWidth="800px"></Container>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseCreateDelete} color="primary">
                  Hủy bỏ
                </Button>
                <Button onClick={() => deleteDateAttendance(row.id)} color="primary">
                  Xóa ngày điểm danh
                </Button>
              </DialogActions>
            </Dialog>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Danh sách điểm danh
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Mã NV</TableCell>
                      <TableCell>Họ Tên</TableCell>
  
                      <TableCell>Cửa hàng</TableCell>
                      <TableCell>Điện thoại</TableCell>
                      <TableCell>Chức vụ</TableCell>
                      <TableCell>Trạng thái</TableCell>
                    </TableRow>
                  </TableHead>
  
                  {row.attendances.length > 0 ? (
                    <TableBody>
                      {row.attendances.map((attendance) => RenderRowAttendance(attendance.employee))}
                    </TableBody>
                  ) : (
                    ''
                  )}
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };