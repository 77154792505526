import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import NoteListHead from 'src/components/_dashboard/note/NoteListHead';
import NoteListToolbar from 'src/components/_dashboard/note/NoteListToolbar';
import NoteMoreMenu from 'src/components/_dashboard/note/NoteMoreMenu';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Grid } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import React, { useCallback } from 'react';
import CallAPI from './../services/CallAPI';
import { ToastContainer, toast } from 'react-toastify';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as Config from '../constants/config';
import { MobileDateTimePicker } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import viLocale from 'date-fns/locale/vi';
import moment from 'moment';
moment.locale('vi');

const TABLE_HEAD = [
  { id: 'stt', label: 'STT', alignRight: false },
  { id: 'username', label: 'Username', alignRight: false },
  { id: 'name', label: 'Tên nhân viên', alignRight: false },
  { id: 'content', label: 'Ghi chú', alignRight: false },
  { id: 'dateTime', label: 'Ngày', alignRight: false }
];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// ----------------------------------------------------------------------

export default function Note() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterUsername, setFilterUsername] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [notesInput, setNotesInput] = useState({
    id: '',
    content: '',
    employee: ''
  });

  const onChange = (e) => {
    setNotesInput({ ...notesInput, [e.target.name]: e.target.value });
  };

  const [openSignUp, setOpenSignUp] = React.useState(false);

  const handleClickOpenSignUp = () => {
    setOpenSignUp(true);
    getEmployees();
  };

  const handleCloseSignUp = () => {
    setOpenSignUp(false);
  };

  const onSubmitSignUp = (e) => {
    e.preventDefault();

    handleCloseSignUp();

    CallAPI(
      'v1/note-of-user',
      'POST',
      {
        content: notesInput.content,
        userId: chooseEmployee,
        datetime: chooseDatetime
      },

      sessionStorage.getItem('jwt')
    )
      .then((res) => {
        if (res.status === 201) {
          toast.success('🦄 Tạo ghi chú thành công!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });

          getNotes();
        }
      })
      .catch((err) => {
        console.log('inside catch block.');
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
          console.log(err.response.data.m);
        } else if (err.request) {
          console.log(err.request);
        } else {
          toast.error('Tạo ghi chú thất bại!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
        console.log(JSON.stringify(err));
      });
  };

  const [notes, setNotes] = useState([]);
  const [totalNotes, setTotalsNotes] = useState(null);

  const getNotes = useCallback(async () => {
    try {
      axios
        .get(
          `${Config.API_URL}v1/note-of-user?limit=${rowsPerPage}&page=${
            page + 1
          }&username=${filterUsername}`,
          {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('jwt') }
          }
        )
        .then((res) => {
          setNotes(res.data.data);
          setTotalsNotes(res.data.total);
        });
    } catch (error) {
      console.log(error);
    }
  }, [rowsPerPage, page, filterUsername]);

  useEffect(() => {
    getNotes();
  }, [rowsPerPage, page, filterUsername, getNotes]);

  const [employees, setEmployees] = useState([]);

  function getEmployees() {
    axios
      .get(`${Config.API_URL}v1/users?role=employee`, {
        headers: { Authorization: 'Bearer ' + sessionStorage.getItem('jwt') }
      })
      .then((res) => {
        setEmployees(res.data.users);
      });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = notes.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterUsername = (event) => {
    setFilterUsername(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalNotes) : 0;

  const isUserNotFound = notes.length === 0;

  const [chooseEmployee, setChooseEmployee] = React.useState('');
  const handleChooseEmployee = (event) => {
    console.log(event.target.value);
    setChooseEmployee(event.target.value);
  };

  const [chooseDatetime, setChooseDatetime] = React.useState('');

  return (
    <Page title="Ghi Chú |  Kim Long Tài">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Danh Sách Ghi Chú
          </Typography>
          <Button
            onClick={handleClickOpenSignUp}
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            Tạo ghi chú
          </Button>
          <Dialog
            open={openSignUp}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseSignUp}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-content"
          >
            <DialogTitle id="alert-dialog-slide-title">{'Tạo thông tin ghi chú'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-content">
                Hành động này sẽ khởi tạo một ghi chú kèm với một nhân viên
              </DialogContentText>
              <Container maxWidth="xl">
                <form>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={viLocale}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={12} lg={12}>
                        <h4>Nhân viên cần ghi chú(*)</h4>
                        <Select
                          fullWidth
                          displayEmpty
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={chooseEmployee}
                          onChange={handleChooseEmployee}
                          defaultValue="Geasoas"
                        >
                          {employees.map((e) => {
                            if (e?.store?.name !== 'VIP') {
                              return (
                                <MenuItem key={e.id} value={e.id}>
                                  {e.username + ' ' + e.fullName}
                                </MenuItem>
                              );
                            }
                            return null;
                          })}
                        </Select>
                        <TextField
                          name="content"
                          margin="normal"
                          id="standard-basic"
                          fullWidth
                          label="Ghi chú"
                          value={notesInput.content}
                          onChange={onChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                      <MobileDateTimePicker
                        label="Chọn Ngày/Thời gian"
                        value={chooseDatetime}
                        onChange={(newValue) => {
                          setChooseDatetime(newValue);
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </Grid>
                  </LocalizationProvider>
                </form>
              </Container>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseSignUp} color="primary">
                Hủy bỏ
              </Button>
              <Button onClick={onSubmitSignUp} color="primary">
                Tạo ghi chú
              </Button>
            </DialogActions>
          </Dialog>
        </Stack>

        <Card>
          <NoteListToolbar
            numSelected={selected.length}
            filterUsername={filterUsername}
            onFilterName={handleFilterUsername}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <NoteListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={totalNotes}
                  // numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {notes.map((row, stt = 0) => {
                    const { id, content, datetime, employee } = row;
                    const isItemSelected = selected.indexOf(id) !== -1;
                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            />
                          </TableCell> */}
                        <TableCell align="left">{++stt}</TableCell>
                        <TableCell align="left">{employee.username}</TableCell>
                        <TableCell align="left">{employee.fullName}</TableCell>
                        <TableCell align="left">{content}</TableCell>

                        <TableCell align="left">
                          {moment(datetime).format('DD/MM/YYYY HH:mm:ss')}
                        </TableCell>

                        <TableCell align="right">
                          <NoteMoreMenu updateNotes={() => getNotes()} idNotes={id} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterUsername} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={totalNotes}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Page>
  );
}
