import editFill from '@iconify/icons-eva/edit-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Container,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  TextField
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { MobileDatePicker, MobileDateTimePicker } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { makeStyles } from '@material-ui/styles';
import viLocale from 'date-fns/locale/vi';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CallAPI from '../../../services/CallAPI';

// ----------------------------------------------------------------------

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 240,
    marginTop: 10
  }
}));
const TimecardMoreMenu = (props) => {
  const classes = useStyles();
  const [numberOfBreaks, setNumberOfBreaks] = React.useState(props.Nob);

  const [chooseStatusWork, setChooseStatusWork] = React.useState(null);

  const handleStatusWork = (event) => {
    setChooseStatusWork(event.target.value);
  };

  const handleNumberOfBreaks = (event) => {
    setNumberOfBreaks(event.target.value);
  };

  const [timecard, setTimecard] = useState({
    date: null,
    beginTime: null,
    breakTime: null,
    doneBreakTime: null,
    breakTime2: null,
    doneBreakTime2: null,
    breakTime3: null,
    doneBreakTime3: null,
    breakTime4: null,
    doneBreakTime4: null,
    endTime: null,
    numberOfBreaks: null,
    statusWork: null
  });

  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  //Delete employee
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDelete = () => {
    setOpen(false);
  };

  //Edit employee
  const [openEdit, setOpenEdit] = React.useState(false);

  const handleClickOpenEdit = (id) => {
    getTimecardById(id);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const onChange = (e) => {
    setTimecard({ ...timecard, [e.target.name]: e.target.value });
  };

  function deleteTimecardById(id) {
    handleCloseDelete();
    CallAPI(`v1/timecards/${id}/delete`, 'PUT', null, sessionStorage.getItem('jwt'))
      .then((res) => {
        if (res.status === 204) {
          toast.success('🦄 Xóa thẻ thời gian thành công!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          props.updateTimecards({});
        }
      })
      .catch((err) => {
        console.log('inside catch block.');
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log('Error', err.message);
          toast.error('Lỗi! Xóa thẻ thời gian thất bại!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          props.updateTimecards({});
        }
        console.log(JSON.stringify(err));
      });
  }

  const onSubmitEdit = (e) => {
    e.preventDefault();
    handleCloseEdit();

    CallAPI(
      `v1/timecards/${props.idTimecard}`,
      'PUT',
      {
        date: timecard.date,
        statusWork: chooseStatusWork,
        beginTime: timecard.beginTime
          ? moment(
              new Date(timecard.date).setHours(
                timecard.beginTime.slice(0, 2),
                timecard.beginTime.slice(3, 5)
              )
            )
          : undefined,
        breakTime: timecard.breakTime
          ? moment(
              new Date(timecard.date).setHours(
                timecard.breakTime.slice(0, 2),
                timecard.breakTime.slice(3, 5)
              )
            )
          : undefined,
        doneBreakTime: timecard.doneBreakTime
          ? moment(
              new Date(timecard.date).setHours(
                timecard.doneBreakTime.slice(0, 2),
                timecard.doneBreakTime.slice(3, 5)
              )
            )
          : undefined,
        breakTime2: timecard.breakTime2
          ? moment(
              new Date(timecard.date).setHours(
                timecard.breakTime2.slice(0, 2),
                timecard.breakTime2.slice(3, 5)
              )
            )
          : undefined,
        doneBreakTime2: timecard.doneBreakTime2
          ? moment(
              new Date(timecard.date).setHours(
                timecard.doneBreakTime2.slice(0, 2),
                timecard.doneBreakTime2.slice(3, 5)
              )
            )
          : undefined,
        breakTime3: timecard.breakTime3
          ? moment(
              new Date(timecard.date).setHours(
                timecard.breakTime3.slice(0, 2),
                timecard.breakTime3.slice(3, 5)
              )
            )
          : undefined,
        doneBreakTime3: timecard.doneBreakTime3
          ? moment(
              new Date(timecard.date).setHours(
                timecard.doneBreakTime3.slice(0, 2),
                timecard.doneBreakTime3.slice(3, 5)
              )
            )
          : undefined,
        breakTime4: timecard.breakTime4
          ? moment(
              new Date(timecard.date).setHours(
                timecard.breakTime4.slice(0, 2),
                timecard.breakTime4.slice(3, 5)
              )
            )
          : undefined,
        doneBreakTime4: timecard.beginTime
          ? moment(
              new Date(timecard.date).setHours(
                timecard.doneBreakTime4.slice(0, 2),
                timecard.doneBreakTime4.slice(3, 5)
              )
            )
          : undefined,
        numberOfBreaks: numberOfBreaks,
        endTime: timecard.endTime,
        userId: props.idUser
      },
      sessionStorage.getItem('jwt')
    )
      .then((res) => {
        if (res.status === 204) {
          toast.success('🦄 Cật nhật thành công', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
        props.updateTimecards({});
      })
      .catch((err) => {
        console.log('inside catch block.');
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log('Error', err.message);
          toast.error('Lỗi! Cật nhật thất bại!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
        console.log(JSON.stringify(err));
      });
  };

  const convertStatusWorkToConstant = (value) => {
    switch (value) {
      case 'Vào ca':
        return 'vao_ca';

      case 'Nghỉ giữa giờ':
        return 'nghi_giua_gio';

      case 'Nghỉ xong':
        return 'nghi_xong';

      case 'Lý do nghỉ giữa giờ':
        return 'ly_do_nghi_giua_gio';

      case 'Kết ca':
        return 'ket_ca';

      default:
        return null;
    }
  };

  function getTimecardById(id) {
    setOpenEdit(true);
    CallAPI(`v1/timecards/${id}`, 'GET', null, sessionStorage.getItem('jwt'))
      .then((res) => {
        setTimecard({
          date: moment(res.data.date),
          beginTime: moment(res.data.beginTime).format('HH:mm'),
          breakTime: moment(res.data.breakTime).format('HH:mm'),
          doneBreakTime: moment(res.data.doneBreakTime).format('HH:mm'),
          breakTime2: moment(res.data.breakTime2).format('HH:mm'),
          doneBreakTime2: moment(res.data.doneBreakTime2).format('HH:mm'),
          breakTime3: moment(res.data.breakTime3).format('HH:mm'),
          doneBreakTime3: moment(res.data.doneBreakTime3).format('HH:mm'),
          breakTime4: moment(res.data.breakTime4).format('HH:mm'),
          doneBreakTime4: moment(res.data.doneBreakTime4).format('HH:mm'),
          numberOfBreaks: res.data.numberOfBreaks,
          endTime: moment(res.data.endTime),
          statusWork: convertStatusWorkToConstant(res.data.statusWork)
        });

        setChooseStatusWork(convertStatusWorkToConstant(res.data.statusWork));
        setNumberOfBreaks(res.data.numberOfBreaks);
      })
      .catch((err) => {
        console.log('inside catch block.');
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log('Error', err.message);
        }
        console.log(JSON.stringify(err));
      });
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleClickOpen} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Xoá" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <Dialog
          fullWidth
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {'Bạn có chắc chắn xóa thẻ thời gian này ?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Hành động này sẽ xóa thẻ thời gian của nhân viên.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete} color="primary">
              Hủy bỏ
            </Button>
            <Button onClick={() => deleteTimecardById(props.idTimecard)} color="primary">
              Xóa
            </Button>
          </DialogActions>
        </Dialog>

        <MenuItem
          onClick={() => handleClickOpenEdit(props.idTimecard)}
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Sửa" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <Dialog
          open={openEdit}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseEdit}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{'Sửa thông tin thẻ thời gian'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Hành động này sẽ cật nhật lại thẻ thời gian cho nhân viên
            </DialogContentText>
            <Container maxWidth="xl">
              <form>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={viLocale}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} lg={12}>
                      <br></br>

                      <MobileDatePicker
                        label="Ngày"
                        value={timecard.date}
                        onChange={(newValue) => {
                          setTimecard({ ...timecard, date: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField required fullWidth name="date" {...params} />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      required
                      id="datetime-local"
                      label="Vào ca"
                      type="time"
                      name="beginTime"
                      value={timecard.beginTime}
                      onChange={onChange}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      id="datetime-local"
                      label="Nghỉ giữa giờ"
                      type="time"
                      name="breakTime"
                      value={timecard.breakTime}
                      onChange={onChange}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      id="datetime-local"
                      label="Nghỉ xong"
                      name="doneBreakTime"
                      type="time"
                      value={timecard.doneBreakTime}
                      onChange={onChange}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      id="datetime-local"
                      label="Nghỉ giữa giờ lần 2"
                      type="time"
                      name="breakTime2"
                      value={timecard.breakTime2}
                      onChange={onChange}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      id="datetime-local"
                      label="Nghỉ xong lần 2"
                      name="doneBreakTime2"
                      type="time"
                      value={timecard.doneBreakTime2}
                      onChange={onChange}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      id="datetime-local"
                      label="Nghỉ giữa giờ lần 3"
                      type="time"
                      name="breakTime3"
                      value={timecard.breakTime3}
                      onChange={onChange}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      id="datetime-local"
                      label="Nghỉ xong lần 3"
                      name="doneBreakTime3"
                      type="time"
                      value={timecard.doneBreakTime3}
                      onChange={onChange}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      id="datetime-local"
                      label="Nghỉ giữa giờ lần 4"
                      type="time"
                      name="breakTime4"
                      value={timecard.breakTime4}
                      onChange={onChange}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>
                    <TextField
                      fullWidth
                      id="datetime-local"
                      label="Nghỉ xong lần 4"
                      name="doneBreakTime4"
                      type="time"
                      value={timecard.doneBreakTime4}
                      onChange={onChange}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <br></br>

                    <MobileDateTimePicker
                      label="Ngày giờ kết ca"
                      value={timecard.endTime}
                      onChange={(newValue) => {
                        setTimecard({ ...timecard, endTime: newValue });
                      }}
                      renderInput={(params) => <TextField fullWidth name="endTime" {...params} />}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} lg={12}>
                    <h4>Số lần nghỉ</h4>
                    <Select
                      className={classes.formControl}
                      displayEmpty
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="numberOfBreaks"
                      value={numberOfBreaks}
                      onChange={handleNumberOfBreaks}
                      defaultValue="Geasoas"
                      fullWidth
                    >
                      <MenuItem value={0}>Chưa nghỉ</MenuItem>
                      <MenuItem value={1}>Nghỉ 1 lần</MenuItem>
                      <MenuItem value={2}>Nghỉ 2 lần</MenuItem>
                      <MenuItem value={3}>Nghỉ 3 lần</MenuItem>
                      <MenuItem value={4}>Nghỉ 4 lần</MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <h4>Trạng thái</h4>
                    <Select
                      className={classes.formControl}
                      displayEmpty
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={chooseStatusWork}
                      onChange={handleStatusWork}
                      fullWidth
                    >
                      <MenuItem value={'vao_ca'}>Vào Ca</MenuItem>
                      <MenuItem value={'nghi_giua_gio'}>Nghỉ Giữa Giờ</MenuItem>
                      <MenuItem value={'ly_do_nghi_giua_gio'}>Lý Do Nghỉ Giữa Giờ</MenuItem>
                      <MenuItem value={'nghi_xong'}>Nghỉ Xong</MenuItem>
                      <MenuItem value={'ket_ca'}>Kết Ca</MenuItem>
                    </Select>
                  </Grid>
                </LocalizationProvider>
              </form>
            </Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit} color="primary">
              Hủy bỏ
            </Button>
            <Button onClick={onSubmitEdit} color="primary">
              Cật nhật
            </Button>
          </DialogActions>
        </Dialog>

        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Menu>
    </>
  );
};
export default TimecardMoreMenu;
