import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Button from '@material-ui/core/Button';
import downloadFill from '@iconify/icons-eva/download-fill';
import { Icon } from '@iconify/react';
import moment from 'moment';
export const ExportCSV = ({ csvData, fileName }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(
      csvData.map((item, stt = 0) => {
        return {
          STT: ++stt,
          'HỌ TÊN':
            item.employee.fullName,
          'NGÀY': moment(item.date).format('DD/MM/YYYY'),
          'TRẠNG THÁI NHÂN VIÊN':
          item.employee.status === 1 && item.employee.isDeleted === false
            ? 'Còn Hiệu Lực'
            : item.employee.isDeleted
            ? 'Đã Nghỉ'
            : item.employee.status === -1 && item.employee.isDeleted === false
            ? 'Tạm Khóa'
            : 'Chờ',
          'VÀO CA': item.beginTime ? moment(item.beginTime).format('HH:mm:ss'):null,
          'KẾT CA':item.endTime? moment(item.endTime).format('DD/MM/YYYY HH:mm:ss'):null,
          'TRẠNG THÁI': item.statusWork,
          'SỐ LẦN NGHỈ': item.numberOfBreaks,
          'TỔNG SỐ GIỜ NGHỈ': item.sumBreakingTime,
          'SỐ GIỜ LÀM VIỆC': item.sumWorkingTime
        };
      })
    );
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      style={{ height: 55 }}
      variant="contained"
      to="#"
      startIcon={<Icon icon={downloadFill} />}
      onClick={(e) => exportToCSV(csvData, fileName)}
    >
      Xuất{' '}
    </Button>
  );
};
