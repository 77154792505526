import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Button,
  Card,
  Container,
  FormControl,
  InputLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
// components
import { Grid, TextField } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { MobileDatePicker } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { makeStyles } from '@material-ui/styles';
import axios from 'axios';
import viLocale from 'date-fns/locale/vi';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import * as Config from '../constants/config';
import CallAPI from './../services/CallAPI';

const TABLE_HEAD = [
  { id: 'stt', label: 'STT', alignRight: false },
  { id: 'username', label: 'Username', alignRight: false },
  { id: 'fullName', label: 'Họ Tên', alignRight: false },
  { id: 'dob', label: 'Ngày Sinh', alignRight: false },
  { id: 'phoneNumber', label: 'Điện thoại', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'store', label: 'Cửa hàng', alignRight: false },
  { id: 'statusCodeEmployee', label: 'Trình trạng', alignRight: false },
  { id: 'role', label: 'Vai trò', alignRight: false }
];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 240,
    marginTop: 10
  }
}));
// ----------------------------------------------------------------------

export default function User() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterUsername, setFilterUsername] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [alertValidate, setAlertValidate] = useState('');

  const [user, setUser] = useState({
    id: '',
    email: '',
    username: '',
    phoneNumber: '',
    fullName: '',
    address: '',
    nric: '',
    password: '',
    rePassword: '',
    statusCodeEmployee: '',
    dob: '',

    role: ''
  });
  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const [chooseStore, setChooseStore] = React.useState('');
  const [chooseRole, setChooseRole] = React.useState('');
  const handleChooseStore = (event) => {
    setChooseStore(event.target.value);
  };

  const handleChooseRole = (event) => {
    setChooseRole(event.target.value);
  };

  //Edit employee
  const [openSignUp, setOpenSignUp] = React.useState(false);

  const handleClickOpenSignUp = () => {
    setOpenSignUp(true);
    getStores();
  };

  const handleCloseSignUp = () => {
    setOpenSignUp(false);
  };

  const onSubmitSignUp = (e) => {
    e.preventDefault();
    if (user.password === user.rePassword) {
      handleCloseSignUp();
      CallAPI(
        'v1/auth/web/register',
        'POST',
        {
          username: user.username,
          fullName: user.fullName,
          dob: user.dob,
          phoneNumber: user.phoneNumber,
          nric: user.nric,
          email: user.email,
          address: user.address,
          password: user.password,
          role: [chooseRole],
          storeId: chooseStore
        },

        sessionStorage.getItem('jwt')
      )
        .then((res) => {
          if (res.status === 201) {
            toast.success('🦄 Tạo nhân viên thành công!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });

            getEmployee();
          }
        })
        .catch((err) => {
          console.log('inside catch block.');
          if (err.response) {
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
            console.log(err.response.data.m);
          } else if (err.request) {
            console.log(err.request);
          } else {
            toast.error('Tạo nhân viên thất bại! Kiểm tra lại!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
          console.log(JSON.stringify(err));
        });
    } else {
      setAlertValidate('Kiểm tra lại mật khẩu!');
    }
  };
  const [stores, setStores] = useState([]);

  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(null);

  function getStores() {
    axios
      .get(`${Config.API_URL}v1/stores?name=`, {
        headers: { Authorization: 'Bearer ' + sessionStorage.getItem('jwt') }
      })
      .then((res) => {
        setStores(res.data.data);
      });
  }

  const getEmployee = useCallback(async () => {
    try {
      axios
        .get(
          `${Config.API_URL}v1/users?limit=${rowsPerPage}&page=${
            page + 1
          }&username=${filterUsername}`,
          {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('jwt') }
          }
        )
        .then((res) => {
          setUsers(res.data.users);
          setTotalUsers(res.data.total);
        });
    } catch (error) {
      console.log(error);
    }
  }, [rowsPerPage, page, filterUsername]);

  useEffect(() => {
    getEmployee();
  }, [rowsPerPage, page, filterUsername, getEmployee]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterUsername(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalUsers) : 0;

  const isUserNotFound = totalUsers === 0;

  const formatDateFrame = (date) => {
    var d = new Date(date);
    var n = d.toLocaleDateString('vi-VN');

    return n;
  };
  const handleClickStatusChangeFalse = (id) => {
    CallAPI(`v1/users/${id}/suspend/on`, 'PUT', null, sessionStorage.getItem('jwt'))
      .then((res) => {
        if (res.status === 204) {
          toast.success('🦄 Cật nhật thành công', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
        getEmployee();
      })
      .catch((err) => {
        console.log('inside catch block.');
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log('Error', err.message);
          toast.error('Lỗi! Cật nhật thất bại!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
        console.log(JSON.stringify(err));
      });
  };
  const classes = useStyles();

  const handleClickStatusChangeTrue = (id) => {
    CallAPI(`v1/users/${id}/suspend/off`, 'PUT', null, sessionStorage.getItem('jwt'))
      .then((res) => {
        if (res.status === 204) {
          toast.success('🦄 Cật nhật thành công', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
        getEmployee();
      })
      .catch((err) => {
        console.log('inside catch block.');
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log('Error', err.message);
          toast.error('Lỗi! Cật nhật thất bại!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
        console.log(JSON.stringify(err));
      });
  };

  return (
    <Page title="User |  Kim Long Tài">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Danh Sách Nhân Viên
          </Typography>
          <Button
            onClick={handleClickOpenSignUp}
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            Tạo thông tin nhân viên
          </Button>
          <Dialog
            open={openSignUp}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseSignUp}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">
              {'Đăng ký thông tin nhân viên'}
              <span style={{ color: 'red' }}> {alertValidate}</span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Hành động này sẽ khởi tạo một nhân viên mới
              </DialogContentText>
              <Container maxWidth="lg">
                <br></br>
                <form>
                  <LocalizationProvider dateAdapter={AdapterDateFns} locale={viLocale}>
                    <Grid container spacing={3}>
                      <TextField
                        required
                        name="username"
                        margin="normal"
                        id="standard-basic"
                        fullWidth
                        label="Username"
                        value={user.username}
                        onChange={onChange}
                      />
                      <TextField
                        required
                        name="fullName"
                        margin="normal"
                        id="standard-basic"
                        fullWidth
                        label="Họ Tên"
                        value={user.fullName}
                        onChange={onChange}
                      />

                      <MobileDatePicker
                        label="Ngày sinh"
                        value={user.dob}
                        onChange={(newValue) => {
                          setUser({ ...user, dob: newValue });
                        }}
                        renderInput={(params) => <TextField required fullWidth {...params} />}
                      />

                      <TextField
                        required
                        name="email"
                        margin="normal"
                        id="standard-basic"
                        fullWidth
                        label="Email"
                        value={user.email}
                        onChange={onChange}
                      />

                      <FormControl required fullWidth>
                        <InputLabel id="demo-simple-select-required-label">Vai Trò</InputLabel>
                        <Select
                          labelId="demo-simple-select-required-label"
                          id="demo-simple-select-required"
                          value={chooseRole}
                          label="Vai Trò *"
                          onChange={handleChooseRole}
                          defaultValue="employee"
                        >
                          <MenuItem value={'employee'}>Nhân Viên</MenuItem>
                          <MenuItem value={'manage'}>Quản Lý</MenuItem>
                        </Select>
                      </FormControl>

                      <TextField
                        name="nric"
                        type="number"
                        margin="normal"
                        id="standard-basic"
                        fullWidth
                        label="CMND/CCCD"
                        value={user.nric}
                        onChange={onChange}
                      />
                      <TextField
                        name="address"
                        margin="normal"
                        id="standard-basic"
                        fullWidth
                        label="Địa chỉ"
                        value={user.address}
                        onChange={onChange}
                      />

                      <TextField
                        name="phoneNumber"
                        type="number"
                        margin="normal"
                        id="standard-basic"
                        fullWidth
                        label="SĐT"
                        value={user.phoneNumber}
                        onChange={onChange}
                      />
                      <TextField
                        required
                        type="password"
                        name="password"
                        margin="normal"
                        id="standard-basic"
                        fullWidth
                        label="Mật khẩu"
                        value={user.password}
                        onChange={onChange}
                      />

                      <TextField
                        required
                        name="rePassword"
                        type="password"
                        margin="normal"
                        id="standard-basic"
                        fullWidth
                        label="Nhập lại mật khẩu"
                        value={user.rePassword}
                        onChange={onChange}
                      />

                      <h4>Cửa hàng làm việc (*)</h4>
                      <Select
                        className={classes.formControl}
                        displayEmpty
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={chooseStore}
                        onChange={handleChooseStore}
                        defaultValue="Geasoas"
                      >
                        {stores.map((e) => {
                          if (e.name !== 'VIP') {
                            return (
                              <MenuItem key={e.id} value={e.id}>
                                {e.name + ' ' + e.address}
                              </MenuItem>
                            );
                          }
                          return null;
                        })}
                      </Select>
                    </Grid>
                  </LocalizationProvider>
                </form>
              </Container>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseSignUp} color="primary">
                Hủy bỏ
              </Button>
              <Button onClick={onSubmitSignUp} color="primary">
                Tạo mới
              </Button>
            </DialogActions>
          </Dialog>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterUsername={filterUsername}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={totalUsers}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {users.map((row, stt = 0) => {
                    const { id, username, fullName, role, email, phoneNumber, status, store, dob } =
                      row;
                    const isItemSelected = selected.indexOf(username) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, username)}
                            />
                          </TableCell> */}
                        <TableCell align="left">{++stt}</TableCell>
                        <TableCell align="left">{username}</TableCell>
                        <TableCell align="left">{fullName}</TableCell>
                        <TableCell align="left">{formatDateFrame(dob)}</TableCell>
                        <TableCell align="left">{phoneNumber}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{store?.name}</TableCell>
                        <TableCell align="left">
                          {status === 'Active' ? (
                            <Chip
                              color="primary"
                              label="Còn hiệu lực"
                              onClick={() => handleClickStatusChangeFalse(id)}
                            />
                          ) : (
                            <Chip
                              color="secondary"
                              label="Tạm khóa"
                              onClick={() => handleClickStatusChangeTrue(id)}
                            />
                          )}
                        </TableCell>

                        <TableCell align="left">{role}</TableCell>

                        <TableCell align="right">
                          <UserMoreMenu
                            updateEmployee={() => getEmployee()}
                            idEmployee={id}
                            idStore={store?.id}
                            role={
                              role === 'Nhân viên' ? 'employee' : role === 'Quản lý' ? 'manage' : ''
                            }
                            status={status === 'Active' ? 1 : status === 'Pending' ? 0 : -1}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterUsername} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalUsers}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Page>
  );
}
