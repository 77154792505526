import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Button,
  ButtonGroup,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
// components
import refreshFill from '@iconify/icons-eva/refresh-fill';
import { Icon } from '@iconify/react';
import { TextField } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { MobileDatePicker } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import axios from 'axios';
import viLocale from 'date-fns/locale/vi';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import TimecardListHead from 'src/components/_dashboard/timecards/TimecardListHead';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import * as Config from '../constants/config';
import { ExportCSV } from './../components/ExportCSV';

const TABLE_HEAD = [
  { id: 'STT', label: 'STT', alignRight: false },
  { id: 'date', label: 'Ngày', alignRight: false },
  { id: 'username', label: 'Mã NV', alignRight: false },
  { id: 'fullName', label: 'Họ Tên', alignRight: false },
  { id: 'statusEmp', label: 'Trạng Thái NV', alignRight: false },
  { id: 'beginTime', label: 'Vào Ca', alignRight: false },
  { id: 'endTime', label: 'Kết Ca', alignRight: false },
  { id: 'numberOfBreaks', label: 'Số Lần Nghỉ', alignRight: false },
  { id: 'numberOfHoursBreaks', label: 'Tổng Số Giờ Nghỉ', alignRight: false },
  { id: 'Sum', label: 'Tổng Số Giờ Công', alignRight: false }
];

// ----------------------------------------------------------------------

export default function Report() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [chooseUsernameFilter, setChooseUsernameFilter] = React.useState('');
  const [chooseFromDate, setChooseFromDate] = React.useState(moment(new Date()).startOf('month'));

  const [chooseToDate, setChooseToDate] = React.useState(new Date());

  const [timecards, setTimecards] = useState([]);
  const [totalTimecards, setTotalTimecards] = useState(null);

  const getTimecards = useCallback(async () => {
    const fromISODate = moment(chooseFromDate, 'DD/MM/YYYY').toISOString();
    const toISODate = moment(chooseToDate, 'DD/MM/YYYY').toISOString();
    try {
      axios
        .get(
          `${Config.API_URL}v1/timecards?limit=${rowsPerPage}&page=${
            page + 1
          }&username=${chooseUsernameFilter}&from=${fromISODate}&to=${toISODate}`,

          {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('jwt') }
          }
        )
        .then((res) => {
          setTimecards(res.data.data);
          setTotalTimecards(res.data.total);
        });
    } catch (error) {
      console.log(error);
    }
  }, [rowsPerPage, page, chooseFromDate, chooseToDate, chooseUsernameFilter]);

  useEffect(() => {
    getTimecards();
  }, [rowsPerPage, page, chooseFromDate, chooseToDate, chooseUsernameFilter, getTimecards]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = timecards.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeChooseUsername = (event) => {
    setChooseUsernameFilter(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalTimecards) : 0;

  const converDateTimeFormat = (dateTime) => {
    var d = new Date(dateTime);
    var date = d.toLocaleDateString('vi-VN');

    return date;
  };

  const handleRefresh = () => {
    setChooseFromDate(moment(new Date()).startOf('month'));
    setChooseToDate(new Date());
    setChooseUsernameFilter('');
  };

  const transferReportByMonth = () => {
    navigate('/dashboard/reports/month', { replace: false });
  };

  const transferReportByEmployee = () => {
    navigate('/dashboard/reports/employee', { replace: false });
  };

  return (
    <Page title="Báo Cáo |  Kim Long Tài">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Báo Cáo Chấm Công Theo Nhân Viên
          </Typography>
        </Stack>
        <ButtonGroup aria-label="outlined primary button group">
          <Button variant="outlined" color="warning" onClick={transferReportByMonth}>
            THÁNG
          </Button>
          <Button variant="outlined" color="warning" onClick={transferReportByEmployee}>
            {' '}
            NHÂN VIÊN
          </Button>
        </ButtonGroup>
        <br></br>
        <Card>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
            mt={5}
            ml={2}
            mr={2}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={viLocale}>
              <MobileDatePicker
                label="Từ Ngày"
                value={chooseFromDate}
                onChange={(newValue) => {
                  setChooseFromDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField style={{ margin: '5px' }} fullWidth {...params} />
                )}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={viLocale}>
              <MobileDatePicker
                label="Đến Ngày"
                value={chooseToDate}
                onChange={(newValue) => {
                  setChooseToDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField style={{ margin: '5px' }} fullWidth {...params} />
                )}
              />
            </LocalizationProvider>

            <TextField
              fullWidth
              style={{ margin: '5px' }}
              id="outlined-basic"
              label="Mã nhân viên"
              variant="outlined"
              value={chooseUsernameFilter}
              onChange={handleChangeChooseUsername}
            />

            <Button
              onClick={handleRefresh}
              style={{ height: 55, margin: '5px' }}
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<Icon icon={refreshFill} />}
            ></Button>
            <ExportCSV csvData={timecards} fileName={'data'} />
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TimecardListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={totalTimecards}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {timecards.map((row, stt = 0) => {
                    const {
                      id,
                      date,
                      employee,
                      statusWork,
                      beginTime,
                      numberOfBreaks,
                      sumBreakingTime,
                      sumWorkingTime,
                      endTime
                    } = row;
                    const isItemSelected = selected.indexOf(id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            />
                          </TableCell> */}
                        <TableCell align="left">{++stt}</TableCell>
                        <TableCell align="left">{converDateTimeFormat(date)}</TableCell>
                        <TableCell align="left">{employee.username}</TableCell>
                        <TableCell align="left">{employee.fullName}</TableCell>
                        <TableCell align="left">
                          {employee.status === 1 && employee.isDeleted === false ? (
                            <Chip size="small" color="primary" label="Còn Hiệu Lực" />
                          ) : employee.isDeleted ? (
                            <Chip color="warning" size="small" label="Đã Nghỉ" />
                          ) : employee.status === -1 && employee.isDeleted === false ? (
                            <Chip color="secondary" size="small" label="Đã Khóa" />
                          ) : (
                            <Chip color="secondary" size="small" label="Chờ" />
                          )}
                        </TableCell>

                        <TableCell align="left">{moment(beginTime).format('HH:mm:ss')}</TableCell>
                        <TableCell align="left">
                          {statusWork === 'Kết ca'
                            ? moment(endTime).format('DD/MM/YYYY HH:mm:ss')
                            : 'đang làm việc'}
                        </TableCell>
                        <TableCell align="left">{numberOfBreaks}</TableCell>

                        <TableCell align="left">
                          {statusWork === 'Kết ca' ? sumBreakingTime : 'đang làm việc'}
                        </TableCell>

                        <TableCell align="left">
                          {statusWork === 'Kết ca' ? sumWorkingTime : 'đang làm việc'}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalTimecards}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Page>
  );
}
