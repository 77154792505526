import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
// components
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Grid, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import RegularMessageListHead from 'src/components/_dashboard/regular-messages/RegularMessageListHead';
import RegularMessageMoreMenu from 'src/components/_dashboard/regular-messages/RegularMessageMoreMenu';
import RegularMessageListToolbar from 'src/components/_dashboard/regular-messages/RegularMessageToolbar';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import * as Config from '../constants/config';
import CallAPI from './../services/CallAPI';

const TABLE_HEAD = [
  { id: 'stt', label: 'STT', alignRight: false },
  { id: 'content', label: 'Nội dung', alignRight: false }
];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// ----------------------------------------------------------------------

export default function RegularMessage() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [regularMessagesInput, setRegularMessagesInput] = useState({
    content: ''
  });

  const onChange = (e) => {
    setRegularMessagesInput({ ...regularMessagesInput, [e.target.name]: e.target.value });
  };

  const [openSignUp, setOpenSignUp] = React.useState(false);

  const handleClickOpenSignUp = () => {
    setOpenSignUp(true);
  };

  const handleCloseSignUp = () => {
    setOpenSignUp(false);
  };

  const onSubmitSignUp = (e) => {
    e.preventDefault();

    handleCloseSignUp();
    CallAPI(
      'v1/regular-message',
      'POST',
      {
        content: regularMessagesInput.content
      },

      sessionStorage.getItem('jwt')
    )
      .then((res) => {
        if (res.status === 201) {
          toast.success('🦄 Tạo nội dung tin nhắn thành công!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });

          getRegularMessages();
        }
      })
      .catch((err) => {
        console.log('inside catch block.');
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
          console.log(err.response.data.m);
        } else if (err.request) {
          console.log(err.request);
        } else {
          toast.error('Tạo nội dung tin nhắn thất bại!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
        console.log(JSON.stringify(err));
      });
  };

  const [regularMessages, setRegularMessages] = useState([]);

  const [totalRegularMessages, setTotalRegularMessages] = useState([]);

  const getRegularMessages = useCallback(async () => {
    try {
      axios
        .get(
          `${Config.API_URL}v1/regular-message?limit=${rowsPerPage}&page=${
            page + 1
          }&content=${filterName}`,
          {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('jwt') }
          }
        )
        .then((res) => {
          setRegularMessages(res.data.data);
          setTotalRegularMessages(res.data.total);
        });
    } catch (error) {
      console.log(error);
    }
  }, [rowsPerPage, page, filterName]);

  useEffect(() => {
    getRegularMessages();
  }, [rowsPerPage, page, filterName, getRegularMessages]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = regularMessages.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRegularMessages) : 0;

  const isRegularMessageNotFound = regularMessages.length === 0;

  return (
    <Page title="Thiết Lập Tin Nhắn Trên Mobile |  Kim Long Tài">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Thiết Lập Tin Nhắn Trên Mobile
          </Typography>
          <Button
            onClick={handleClickOpenSignUp}
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            Tạo nội dung tin nhắn
          </Button>
          <Dialog
            open={openSignUp}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseSignUp}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{'Tạo nội dung tin nhắn'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Hành động này sẽ khởi tạo một nội dung tin nhắn
              </DialogContentText>
              <Container maxWidth="xl">
                <br></br>
                <form>
                  <Grid container spacing={3}>
                    <TextField
                      fullWidth
                      name="content"
                      margin="normal"
                      id="standard-basic"
                      label="Nội dung"
                      value={regularMessagesInput.content}
                      onChange={onChange}
                    />
                  </Grid>
                </form>
              </Container>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseSignUp} color="primary">
                Hủy bỏ
              </Button>
              <Button onClick={onSubmitSignUp} color="primary">
                Tạo nội dung
              </Button>
            </DialogActions>
          </Dialog>
        </Stack>

        <Card>
          <RegularMessageListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <RegularMessageListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={totalRegularMessages}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {regularMessages.map((row, stt = 0) => {
                    const { id, content } = row;
                    const isItemSelected = selected.indexOf(id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            onChange={(event) => handleClick(event, id)}
                                                        />
                                                    </TableCell> */}
                        <TableCell align="left">{++stt}</TableCell>
                        <TableCell align="left">{content}</TableCell>

                        <TableCell align="right">
                          <RegularMessageMoreMenu
                            updateRegularMessages={() => getRegularMessages()}
                            idRegularMessage={id}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isRegularMessageNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalRegularMessages}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Page>
  );
}
