import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Paper,
  Slide,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios';
import CallAPI from '../../services/CallAPI';
import * as Config from '../../constants/config';
import { Icon } from '@iconify/react';
import Page from 'src/components/Page';
import plusFill from '@iconify/icons-eva/plus-fill';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import viLocale from 'date-fns/locale/vi';
import { LoadingButton, MobileDatePicker } from '@material-ui/lab';
import moment from 'moment';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Row(props) {
  const [isSubmittingDelete, setIsSubmittingDelete] = React.useState(false);
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  const [openCreateDeleteDateAttendance, setOpenCreateDeleteDateAttendance] = React.useState(false);

  const handleClickOpenCreateDelete = () => {
    setOpenCreateDeleteDateAttendance(true);
  };

  const handleCloseCreateDelete = () => {
    setOpenCreateDeleteDateAttendance(false);
  };

  const deleteDateAttendance = (date) => {
    handleCloseCreateDelete();
    setIsSubmittingDelete(true);
    CallAPI(
      `v1/attendances/employees/delete`,
      'PUT',
      { date: moment(date, 'DD/MM/YYYY').toISOString() },
      sessionStorage.getItem('jwt')
    )
      .then((res) => {
        setIsSubmittingDelete(false);
        if (res.status === 204) {
          toast.success('🦄 Xóa điểm danh theo ngày thành công! ', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          props.handleLoad()
        }
      })
      .catch((err) => {
        console.log('inside catch block.');
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log('Error', err.message);
          toast.error('Lỗi! Thất bại', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
        console.log(JSON.stringify(err));
      });
  };

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.date}
        </TableCell>
        <TableCell component="th" scope="row">
          <LoadingButton
            onClick={handleClickOpenCreateDelete}
            loading={isSubmittingDelete}
            variant="outlined"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={trash2Fill} />}
            color="primary"
          >
            Xóa danh sách điểm danh theo ngày
          </LoadingButton>
        </TableCell>

        <Dialog
          open={openCreateDeleteDateAttendance}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseCreateDelete}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Bạn có chắc chắn muốn xóa ngày điểm danh này ?
            </DialogContentText>

            <Container maxWidth="800px"></Container>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCreateDelete} color="primary">
              Hủy bỏ
            </Button>
            <Button onClick={() => deleteDateAttendance(row.date)} color="primary">
              Xóa ngày điểm danh
            </Button>
          </DialogActions>
        </Dialog>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Danh sách điểm danh
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Mã NV</TableCell>
                    <TableCell>Họ Tên</TableCell>
                    <TableCell>Cửa Hàng</TableCell>
                    <TableCell>SĐT</TableCell>
                    <TableCell>Chức Vụ</TableCell>
                    <TableCell>Trạng Thái</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.attendances.map((attendance) => (
                    <TableRow key={attendance?.employee?.id}>
                      <TableCell component="th" scope="row">
                        {attendance?.employee?.username}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {attendance?.employee?.fullName}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {attendance?.employee?.store?.name}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {attendance?.employee?.phoneNumber}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {attendance?.employee?.role === 'Quản lý' ? (
                          <Chip label="Quản lý" />
                        ) : attendance?.employee?.role === 'Nhân viên' ? (
                          <Chip color="secondary" variant="outlined" label="Nhân Viên" />
                        ) : (
                          <Chip color="warning" variant="outlined" label="Developer" />
                        )}
                      </TableCell>

                      <TableCell>
                        {attendance.check === true ? (
                          <Chip
                            color="secondary"
                            label="Đã điểm danh"
                            onClick={() => props.handleClickChangeCheckAttendance(attendance.id)}
                          />
                        ) : (
                          <Chip
                            color="primary"
                            label="Chưa điểm danh"
                            onClick={() => props.handleClickChangeCheckAttendance(attendance.id)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function NewAttendance() {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [chooseDatetime, setChooseDatetime] = React.useState('');
  const [openCreateDateAttendance, setOpenCreateDateAttendance] = React.useState(false);
  function getDatesAttendances() {
    axios
      .get(`${Config.API_URL}v1/attendances/list-by-date?limit=7`, {
        headers: { Authorization: 'Bearer ' + sessionStorage.getItem('jwt') }
      })
      .then((res) => {
        setData(res.data.data);
      });
  }

  const handleClickChangeCheckAttendance = (id) => {
    CallAPI(`v1/attendances/${id}`, 'PUT', null, sessionStorage.getItem('jwt'))
      .then((res) => {
        if (res.status === 204) {
          toast.success('🦄 Cật nhật thành công', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });

          getDatesAttendances();
        }
      })
      .catch((err) => {
        console.log('inside catch block.');
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log('Error', err.message);
          toast.error('Lỗi! Cật nhật thất bại!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }

        console.log(JSON.stringify(err));
      });
  };

  function changeCheckAttendance(id) {
    handleClickChangeCheckAttendance(id);
  }
  const handleCloseCreate = () => {
    setOpenCreateDateAttendance(false);
  };

  React.useEffect(() => {
    getDatesAttendances();
  }, []);

  const handleClickOpenCreate = () => {
    setOpenCreateDateAttendance(true);
  };

  const createDateAttendanceDialog = (e) => {
    e.preventDefault();
    handleCloseCreate();
    setIsSubmitting(true);
    CallAPI(
      'v1/attendances/employees',
      'POST',
      {
        date: chooseDatetime
      },
      sessionStorage.getItem('jwt')
    )
      .then((res) => {
        if (res.status === 201) {
          setIsSubmitting(false);
          toast.success('🦄 Tạo danh sách điểm danh ngày thành công!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
          getDatesAttendances();
        }
      })
      .catch((err) => {
        console.log('inside catch block.');
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
          console.log(err.response.data.m);
        } else if (err.request) {
          console.log(err.request);
        } else {
          setIsSubmitting(false);

          toast.error('Tạo danh sách điểm danh thất bại!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
        console.log(JSON.stringify(err));
      });
  };


  return (
    <Page title="Theo Dõi Điểm Danh Hằng Ngày | Kim Long Tài">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Theo Dõi Điểm Danh Hằng Ngày
          </Typography>
          <LoadingButton
            onClick={handleClickOpenCreate}
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
            loading={isSubmitting}
          >
            Tạo Ngày Điểm Danh
          </LoadingButton>

          <Dialog
            open={openCreateDateAttendance}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseCreate}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            maxWidth={400}
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description"></DialogContentText>

              <Container>
                <form>
                  <Grid item xs={12} md={12} lg={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={viLocale}>
                      <MobileDatePicker
                        label="Chọn ngày để tạo phiếu"
                        value={chooseDatetime}
                        onChange={(newValue) => {
                          setChooseDatetime(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </form>
              </Container>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCreate} color="primary">
                Hủy bỏ
              </Button>
              <Button onClick={createDateAttendanceDialog} color="primary">
                Tạo
              </Button>
            </DialogActions>
          </Dialog>
        </Stack>

        <Card sx={{ minWidth: '800px' }}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="left">Ngày</TableCell>
                  <TableCell align="left">Thao Tác</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <Row
                    key={row.date}
                    row={row}
                    handleClickChangeCheckAttendance={changeCheckAttendance}
                    handleLoad={getDatesAttendances}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Page>
  );
}
