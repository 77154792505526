// ----------------------------------------------------------------------

const account = {
  displayName: '',
  email: '',
  photoURL: '/static/mock-images/avatars/avatar_default.jpg',
  firstName: '',
  lastName: '',
  phone: '',
  username: '',
  id: '',
  role: ''
};

export default account;
