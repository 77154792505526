import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
// components
import { Grid, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import axios from 'axios';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import StoreListHead from 'src/components/_dashboard/store/StoreListHead';
import StoreListToolbar from 'src/components/_dashboard/store/StoreListToolbar';
import StoreMoreMenu from 'src/components/_dashboard/store/StoreMoreMenu';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import * as Config from '../constants/config';
import CallAPI from './../services/CallAPI';

const TABLE_HEAD = [
  { id: 'STT', label: 'STT', alignRight: false },
  { id: 'name', label: 'Tên Cửa Hàng', alignRight: false },
  { id: 'address', label: 'Địa Chỉ', alignRight: false },
  { id: 'action', label: 'Số lượng nhân viên', alignRight: false }
];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// ----------------------------------------------------------------------

export default function Store() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [storeInput, setStoreInput] = useState({
    name: '',
    address: ''
  });

  const onChange = (e) => {
    setStoreInput({ ...storeInput, [e.target.name]: e.target.value });
  };

  //Edit employee
  const [openSignUp, setOpenSignUp] = React.useState(false);

  const handleClickOpenSignUp = () => {
    setOpenSignUp(true);
  };

  const handleCloseSignUp = () => {
    setOpenSignUp(false);
  };

  const onSubmitStore = (e) => {
    e.preventDefault();

    handleCloseSignUp();
    CallAPI(
      'v1/stores',
      'POST',
      {
        name: storeInput.name,
        address: storeInput.address
      },

      sessionStorage.getItem('jwt')
    )
      .then((res) => {
        if (res.status === 201) {
          toast.success('🦄 Tạo cửa hàng thành công!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });

          getStores();
        }
      })
      .catch((err) => {
        console.log('inside catch block.');
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
          console.log(err.response.data.m);
        } else if (err.request) {
          console.log(err.request);
        } else {
          toast.error('Tạo cửa hàng thất bại! ', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          });
        }
        console.log(JSON.stringify(err));
      });
  };

  const [stores, setStores] = useState([]);
  const [totalStores, setTotalStores] = useState(null);

  const getStores = useCallback(async () => {
    try {
      axios
        .get(
          `${Config.API_URL}v1/stores?limit=${rowsPerPage}&page=${page + 1}&name=${filterName}`,
          {
            headers: { Authorization: 'Bearer ' + sessionStorage.getItem('jwt') }
          }
        )
        .then((res) => {
          setStores(res.data.data);
          setTotalStores(res.data.total);
        });
    } catch (error) {
      console.log(error);
    }
  }, [rowsPerPage, page, filterName]);

  useEffect(() => {
    getStores();
  }, [rowsPerPage, page, filterName, getStores]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = stores.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalStores) : 0;

  const isUserNotFound = totalStores === 0;

  return (
    <Page title="Cửa Hàng | Kim Long Tài">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Danh Sách Cửa Hàng
          </Typography>
          <Button
            onClick={handleClickOpenSignUp}
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
          >
            Tạo thông tin cửa hàng
          </Button>
          <Dialog
            open={openSignUp}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseSignUp}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{'Tạo cửa hàng'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Hành động này sẽ khởi tạo một cửa hàng mới
              </DialogContentText>
              <Container maxWidth="xl">
                <form>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        required
                        name="name"
                        margin="normal"
                        id="standard-basic"
                        fullWidth
                        label="Tên cửa hàng"
                        value={stores.username}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <TextField
                        name="address"
                        margin="normal"
                        id="standard-basic"
                        fullWidth
                        label="Địa chỉ"
                        value={stores.email}
                        onChange={onChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={4}></Grid>
                </form>
              </Container>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseSignUp} color="primary">
                Hủy bỏ
              </Button>
              <Button onClick={onSubmitStore} color="primary">
                Tạo mới
              </Button>
            </DialogActions>
          </Dialog>
        </Stack>

        <Card>
          <StoreListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <StoreListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={totalStores}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {stores.map((row, stt = 0) => {
                    const { id, name, address, amountEmployee } = row;
                    const isItemSelected = selected.indexOf(id) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, id)}
                          />
                        </TableCell> */}
                        <TableCell align="left">{++stt}</TableCell>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{address}</TableCell>
                        <TableCell align="left">{amountEmployee}</TableCell>

                        <TableCell align="right">
                          <StoreMoreMenu updateStore={() => getStores()} idStore={id} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalStores}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Page>
  );
}
