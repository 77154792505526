import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import { Grid, TablePagination, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TableHead from '@material-ui/core/TableHead';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core';
// components
import Slide from '@material-ui/core/Slide';
import axios from 'axios';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import CallAPI from '../../services/CallAPI';

// import { filter } from 'lodash';
// import SearchNotFound from './../components/SearchNotFound';
import { LoadingButton, MobileDatePicker } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import viLocale from 'date-fns/locale/vi';
import * as Config from '../../constants/config';
import RowAttendance from './RowAttendance';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// ----------------------------------------------------------------------

export default function Attendance() {
  const [page, setPage] = useState(0);
  // const [order, setOrder] = useState('asc');

  // const [orderBy, setOrderBy] = useState('name');
  // const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [rows, setRows] = useState([]);
  const [openCreateDateAttendance, setOpenCreateDateAttendance] = React.useState(false);

  const [chooseDatetime, setChooseDatetime] = React.useState('');

  function getDatesAttendances() {
    axios
      .get(`${Config.API_URL}v1/attendances/list-by-date?limit=1`, {
        headers: { Authorization: 'Bearer ' + sessionStorage.getItem('jwt') }
      })
      .then((res) => {
        setRows(res.data.data);
      });
  }

  var listArray = [];
  var listIdArrayAttendance = [];

  useEffect(() => {
    getDatesAttendances();
  }, []);

  const handleClickOpenCreate = () => {
    setOpenCreateDateAttendance(true);
  };

  const handleCloseCreate = () => {
    setOpenCreateDateAttendance(false);
  };

  const createDateAttendanceDialog = (e) => {
    e.preventDefault();
    handleCloseCreate();
    createAttendance();
    setIsSubmitting(true);
    setTimeout(() => {
      CallAPI(
        '/date-attendances',
        'POST',
        {
          date: chooseDatetime,
          attendances: listIdArrayAttendance
        },

        sessionStorage.getItem('jwt')
      )
        .then((res) => {
          if (res.status === 200) {
            setIsSubmitting(false);
            toast.success('🦄 Tạo danh sách điểm danh ngày thành công!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
        })
        .catch((err) => {
          console.log('inside catch block.');
          if (err.response) {
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
            console.log(err.response.data.m);
          } else if (err.request) {
            console.log(err.request);
          } else {
            setIsSubmitting(false);

            toast.error('Tạo danh sách điểm danh thất bại!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
          console.log(JSON.stringify(err));
        });
      // window.location.reload();
    }, 10000);
  };

  const createAttendanceForUser = (id, data) => {
    CallAPI(
      `/users/${id}`,
      'PUT',
      {
        attendances: data
      },
      sessionStorage.getItem('jwt')
    )
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const createAttendance = () => {
    listArray.map((item) => {
      return CallAPI(
        '/attendances',
        'POST',
        {
          users_permissions_user: item,
          check: false
        },

        sessionStorage.getItem('jwt')
      )
        .then((res) => {
          if (res.status === 200) {
            listIdArrayAttendance.push(res.data.id);
            createAttendanceForUser(item, res.data.id);
          }
        })
        .catch((err) => {
          console.log('inside catch block.');
          if (err.response) {
            console.log(err.response.data);
            console.log(err.response.status);
            console.log(err.response.headers);
            console.log(err.response.data.m);
          } else if (err.request) {
            console.log(err.request);
          } else {
            toast.error('Tạo danh sách điểm danh thất bại!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            });
          }
          console.log(JSON.stringify(err));
        });
    });
  };

  sessionStorage.setItem('date', listArray);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredUsers = rows;

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Theo Dõi Điểm Danh Hằng Ngày | Kim Long Tài">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Theo Dõi Điểm Danh Hằng Ngày
          </Typography>
          <LoadingButton
            onClick={handleClickOpenCreate}
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Icon icon={plusFill} />}
            loading={isSubmitting}
          >
            Tạo Ngày Điểm Danh
          </LoadingButton>

          <Dialog
            open={openCreateDateAttendance}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseCreate}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <br></br>
              </DialogContentText>

              <Container maxWidth="800px">
                <form>
                  <Grid item xs={12} md={12} lg={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={viLocale}>
                      <MobileDatePicker
                        label="Chọn Ngày Tạo Danh Sách Điểm Danh"
                        value={chooseDatetime}
                        onChange={(newValue) => {
                          setChooseDatetime(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </form>
              </Container>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCreate} color="primary">
                Hủy bỏ
              </Button>
              <Button onClick={createDateAttendanceDialog} color="primary">
                Tạo ngày điểm danh
              </Button>
            </DialogActions>
          </Dialog>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Ngày</TableCell>
                    <TableCell>Hàng động</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers.length > 0 &&
                    filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => <RowAttendance key={row.date} row={row} />)}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}></TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Page>
  );
}
